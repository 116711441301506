import React, { useState, useEffect } from 'react';
import Html5QrcodePlugin from './Html5QrcodePlugin.js';
import api from '../../utils/axios.js';
import { useNavigate } from 'react-router-dom';

const qrConfig = { fps: 15, qrbox: { width: 300, height: 300 } };

const ReadQR = ({ setResult, setOpenPopup }) => {
  const [data, setData] = useState("No result");
  const [selected, setSelected] = useState(false);
  const navigate = useNavigate();
  const onNewScanResult = (decodedText) => {
    if(selected) return;
    console.log("App [result]", decodedText);
    console.log(decodedText);
    setData(decodedText);
    setSelected(true);
    debugger
    api.get(decodedText).then((result)=>{
      debugger
      navigate('/customer/productInfo', { state: { productData: result.data.data } });
    });
};
  return (
    <div>

      <div style={{ position: "relative" }}>
        <div id="reader" width="100%" />
        <Html5QrcodePlugin
                    fps={10}
                    qrbox={{ width: 350, height: 350 }}
                    disableFlip={false}
                    qrCodeSuccessCallback={onNewScanResult}
        />
    
      </div>
      <p>{data}</p>
    </div>
  )
}

export default ReadQR