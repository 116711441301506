import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import { CustomizeTextField } from '../../lib/Styles/textField_style';
import Autocomplete from '@mui/material/Autocomplete';
import BtnOutlined, { BtnDefault } from '../BtnComponent';
import GetProductFormOptions from '../../CommonFunctions/GetProductFormOptions';
import { uploadImage } from '../../CommonFunctions/GetStepperData';
import { addNewProduct } from '../../StateMgmt/Actions/productActions';
import _ from 'lodash';
import { errorMessage } from '../../utils/utils';
import GetCustProductOptions from '../../CommonFunctions/GetCustProductOptions';
import api from '../../utils/axios';


const Add_customer_product = ({ setOpenPopup, openPopup }) => {

    const dispatch = useDispatch()

    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [rangeOptions, setRangeOptions] = useState([]);
    const [productId, setProductId] = useState(0);
    const [invoiceReader, setInvoiceReader] = useState('');
    const [invoiceSource, setInvoiceSource] = useState('');
    const [fileName, setFileName] = useState('');

    useEffect(() => {
        GetCustProductOptions().then(({ category }) => {
            console.log(category)
            setCategories(category);
        });
    }, [openPopup])

    const handleInvoice = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setInvoiceReader(reader);
        setFileName(file.name)
        previewFile(reader, setInvoiceSource);
    }
    const previewFile = (reader, source) => {
        reader.onloadend = () => {
            source(reader.result)
        }
    }

    const getSubCategories = (value) => {
        setSubCategories(value.subCategories);
    }

    const getProductRange = (value) => {
        setRangeOptions(value.range);
    }


    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const onSubmit = async (data) => {
        console.log(data)
        console.log(productId)

        let invoiceFile = invoiceReader;
        let error = false;
        if (!invoiceFile) {
            errorMessage('Please Upload Invoice');
            error = true;
        };

        if (!error) {
            const { imageUrl: invoice } = await uploadImage(invoiceFile, 'invoice');
            const reqBody = {
                invoice: invoice,
                ProductId: productId,
                dealerName: data.dealer
            }

            api.post('/api/v1/customer/addProduct', reqBody)
                .then(response => {
                    console.log(response)
                    setOpenPopup(false);
                });
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4 w-full'>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={categories}
                        onChange={(e, value) => { getSubCategories(value) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product Category'
                                name='category'
                                {...register("category", { required: 'Please select a category' })}
                                error={Boolean(errors.category)}
                                helperText={errors.category?.message}
                            />
                        )}

                    />
                </Stack>
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={subCategories}
                        onChange={(e, value) => { getProductRange(value) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product Sub-Category'
                                name='subcategory'
                                {...register("subcategory", { required: 'Please select a sub category' })}
                                error={Boolean(errors.subcategory)}
                                helperText={errors.subcategory?.message}
                            />
                        )}

                    />
                </Stack>



            </div>
            <div className="grid gap-4 grid-cols-1">
                <Stack spacing={2} sx={{ color: '#851851' }}>
                    <Autocomplete
                        options={rangeOptions}
                        onChange={(e, value) => { setProductId(value.id) }}
                        renderInput={(params) => (
                            <CustomizeTextField
                                {...params}
                                label='Select Product'
                                name='range'
                                {...register("range", { required: 'Please select a range' })}
                                error={Boolean(errors.range)}
                                helperText={errors.range?.message}
                            />
                        )}

                    />
                </Stack>
            </div>
            <div className="grid gap-4 grid-cols-1 ">
                <CustomizeTextField
                    label='Enter Dealer Name'
                    name='dealer'
                    {...register("dealer", { required: 'Please enter dealer name' })}
                    error={Boolean(errors.dealer)}
                    helperText={errors.dealer?.message}
                />
            </div>

            <div className=''>

                <Stack spacing={2} sx={{ color: '#851851' }} className='w-full'>


                    <div class="flex items-center justify-start w-full py-3">
                        <label for="dropzone-file1" class="flex flex-col items-start justify-center pl-4 w-full h-16 border-2 border-gray-300 hover:border-black border-dashed rounded-lg cursor-pointer bg-gray-50 ">
                            <div className="flex justify-start gap-2 pt-8 pb-6">
                                <svg aria-hidden="true" class="w-8 h-10 mb-3 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>
                                <div className='flex flex-col'>
                                    <p class="mb-2 text-md text-gray-500 dark:text-gray-400"><span class="font-semibold required">Upload Invoice</span></p>
                                    <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                </div>
                                {invoiceSource && (
                                    <div className='mb-2'>
                                        <span>{fileName.substr(fileName.length - 17)}</span>
                                    </div>
                                )}

                            </div>
                            <input
                                id="dropzone-file1"
                                type="file"
                                class="hidden"
                                onChange={(e) => {
                                    handleInvoice(e)
                                    setInvoiceReader(e.target.files[0]);
                                }}
                            />
                        </label>

                    </div>
                    <p class="text-xs text-end text-gray-500 dark:text-gray-400">SVG, PNG, JPG or GIF (MAX. 800x400px)</p>
                </Stack>


            </div>
            <div className="grid gap-4 grid-cols-2 gap-x-8 ">
                <BtnDefault
                    variant="contained"
                    color="primary"
                    size="medium"
                    text="Submit"
                    type="submit"
                />
                <BtnOutlined
                    variant="outlined"
                    color="error"
                    size="medium"
                    text="Cancel"
                    onClick={() => setOpenPopup(false)}
                />
            </div>
        </form>
    )
}

export default Add_customer_product