import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { TextField, Autocomplete } from '@mui/material';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { GoPlus } from 'react-icons/go'
import { IoBagCheckSharp } from 'react-icons/io5'
import api from '../../utils/axios';
import { errorMessage, successResponse } from '../../utils/utils';
import ModalOpener from '../../Components/ModalOpener';
import { uploadImage } from '../../CommonFunctions/GetStepperData';
import DisplayName from './DisplayName';


const ProductScanInfo = () => {

    const {
        register,
        handleSubmit,
        formState: { errors },
        control
    } = useForm();
    const location = useLocation();
    let productData = location?.state?.productData || '';

    const [fullName, setFullName] = useState(productData.name);
    const [productList, setProductList] = useState(productData.products);
    const [showInputEle, setShowInputEle] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [action, setAction] = useState('');
    const [dealer, setDealer] = useState('');
    const [result, setResult] = useState("");
    const [fileName, setFileName] = useState('');
    const [invoiceReader, setInvoiceReader] = useState('');
    const [invoiceSource, setInvoiceSource] = useState('');
    const [disable, setDisable] = useState(false);
    const [buttonLabel, setButtonLabel] = useState('Submit');


    const defaultProps = {
        options: top100Films,
        getOptionLabel: (option) => option.title,
    };

    const handleInvoice = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setInvoiceReader(reader);
        setFileName(file.name)
        previewFile(reader, setInvoiceSource);
    }
    const previewFile = (reader, source) => {
        reader.onloadend = () => {
            source(reader.result)
        }
    }

    const navigate = useNavigate();

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            onSubmit();
        }
    }
    useEffect(() => {
        const code = result.split('Code=').pop();
        if (code) {
            api.get(`/api/v1/customer/productDetail?productUniqueCode=${code}`).then((result) => {
                const [product] = result.data.data;
                setProductList([...productList, product])
                setOpenPopup(false);
                setResult("");
            });

        }
    }, [openPopup])

    const readQR = () => {
        setAction('ReadQR')
        setOpenPopup(true);
    }
    const onSubmit = async (data) => {
        setDisable(true);
        setButtonLabel('Saving Changes....');
        let invoiceFile = invoiceReader;
        let error = false;
        if (!invoiceFile) {
            errorMessage('Please Upload Invoice');
            error = true;
        };

        if (!error) {
            const { imageUrl: invoice } = await uploadImage(invoiceFile, 'invoice');
            const reqBody = {
                ProductId: productData.productId,
                invoice: invoice,
                dealerName: dealer
            }
            console.log(reqBody)
            api.post('/api/v1/customer/addProduct', reqBody)
                .then(response => {
                    console.log(response)
                    setDisable(false);
                    setButtonLabel('Submit');
                    navigate('/customer/productList');
                })
                .catch(e=>{
                    setButtonLabel('Submit');
                })
                ;
        }

        // navigate('/createpin', {});
    }

    return (
        <div className='grid h-screen w-full mt-44'>
            <div className='flex flex-col justify-center'>
                <div className='max-w-[480px] w-full mx-auto bg-gray-100 shadow-xs shadow-slate-400 p-8 px-4 rounded-lg'>
                    {/* <h2 className='text-2xl text-slate-800 font-bold text-center pb-5'>SAHAYAK - Grafdoer</h2> */}

                    <span className='flex gap-2 py-5'>
                        <IoBagCheckSharp />
                        <h5 className='text-md text-slate-600 font-bold text-center pb-5'>
                            Thankyou for purchasing product
                        </h5>
                    </span>

                    <div className='flex flex-col gap-2'>
                        <div className='flex gap-2 text-gray-600 py-2'>
                            <label className='font-semibold text-lg required'>Product Name: </label>
                            <span className='text-2xl'>{productData.productName}</span>
                        </div>
                        <div className='flex gap-2 text-gray-600 py-2'>
                            <label className='font-semibold text-lg required'>Purchased from: </label>
                            <DisplayName
                                value={fullName}
                                handleChange={(e) => setDealer(e.target.value)}
                                handleDoubleClick={() => setShowInputEle(true)}
                                handleBlur={() => setShowInputEle(false)}
                                showInputEle={showInputEle}
                            />
                        </div>
                        {/* <div className='grid grid-cols-4 py-5'>
                            <div className='col-span-2'>
                                <span className='font-semibold text-lg'> Dealer from whom the product is purchased</span>
                            </div>
                            <div className='col-span-2 py-4'>
                                <Autocomplete
                                    {...defaultProps}
                                    id="auto-complete"
                                    autoComplete
                                    includeInputInList
                                    onChange={(event, value) => {
                                        setDealer(value.title)
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} variant="standard" />
                                    )}
                                />
                            </div>
                        </div> */}
                        {/* {
                            productList.map(product => (
                                <div className='flex gap-4 text-gray-600 py-2'>
                                    <label className='font-semibold text-lg'>Product Details</label>
                                    <span className='text-lg'>{product.productName}</span>
                                </div>
                            ))
                        } */}
                        {/* dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 */}
                        {/* <div className="flex gap-x-3 justify-between items-center p-3 w-full h-auto border-2 border-gray-300 hover:border-black border-dashed rounded-lg cursor-pointer bg-gray-50 "
                            onClick={readQR}
                        >
                            <div className='flex space-x-2 pl-3 py-3'>
                                <span><QrCodeScannerIcon fontSize='large' /></span>
                                <label className=' text-gray-500 dark:text-gray-400 font-semibold text-lg'>Scan More</label>
                            </div>
                            <span className='pr-2'><GoPlus fontSize='large' /></span>
                        </div> */}
                        {/* dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600 */}
                        {/* {result && <span>{result}</span>} */}
                        <div class="flex items-center justify-start w-full py-3">
                            <label for="dropzone-file1" class="flex flex-col items-start justify-center pl-4 w-full h-16 border-2 border-gray-300 hover:border-black border-dashed rounded-lg cursor-pointer bg-gray-50 ">
                                <div className="flex justify-start gap-2 pt-8 pb-6">
                                    <svg aria-hidden="true" class="w-8 h-10 mb-3 text-black" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                            d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                    </svg>
                                    <div className='flex flex-col'>
                                        <p class="mb-2 text-md text-gray-500 dark:text-gray-400"><span class="font-semibold required">Upload Invoice</span></p>
                                        <p class="mb-2 text-sm text-gray-500 dark:text-gray-400"><span class="font-semibold">Click to upload</span></p>
                                    </div>
                                    {invoiceSource && (
                                        <div className='mb-2'>
                                            <span>{fileName.substr(fileName.length-17)}</span>
                                        </div>
                                    )}

                                </div>
                                <input
                                    id="dropzone-file1"
                                    type="file"
                                    class="hidden"
                                    onChange={(e) => {
                                        handleInvoice(e)
                                        setInvoiceReader(e.target.files[0]);
                                    }}
                                />
                            </label>

                        </div>
                    </div>
                    <button
                        className='w-full my-5 py-3 bg-black shadow-lg shadow-blue-500/50 hover:shadow-blue-500/30 text-white font-semibold rounded-lg'
                        type="submit" onClick={onSubmit}
                        disabled={disable}
                    >
                        {buttonLabel}
                    </button>
                </div>

            </div>
            {action &&
                <ModalOpener
                    action={action}
                    openPopup={openPopup}
                    setOpenPopup={setOpenPopup}
                    setResult={setResult}
                // data={productData}
                />
            }
        </div>
    )
}


export default ProductScanInfo;
const top100Films = [
    { title: 'Dealer_1' },
    { title: 'Dealer_2' },
    { title: 'Dealer_3' },
    { title: 'Dealer_4' },
]